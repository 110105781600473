import { tv } from 'tailwind-variants'

export const buttonStyle = tv({
  base: 'w-full text-[#fff] font-bold h-[44px] rounded-lg transition-all flex-center',
  variants: {
    color: {
      primary:
        'bg-[#6A49D9] hover:bg-[#351D87] focus:bg-[#6A49D9] focus:shadow-input disabled:bg-[#C8BCF1] disabled:cursor-not-allowed',
      red: 'bg-[#F04438] hover:bg-[#752a25] focus:bg-[#F04438] focus:shadow-input disabled:bg-[#f1bcbc] disabled:cursor-not-allowed',
      outline:
        'bg-transparent border border-[#D1D1D6] text-[#A0A0AB] disabled:opacity-50 font-normal',
    },
  },
  defaultVariants: {
    color: 'primary',
  },
})
