import { Spinner } from '@phosphor-icons/react'
import { buttonStyle } from 'src/components/Button/styles'
import { twMerge } from 'tailwind-merge'

export const Button = ({ children, isLoading, color, className, ...props }) => {
  return (
    <button {...props} className={twMerge(buttonStyle({ color }), className)}>
      {isLoading ? <Spinner className="animate-spin text-white" /> : children}
    </button>
  )
}
