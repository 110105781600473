'use client'
import { yupResolver } from '@hookform/resolvers/yup'
import { EnvelopeSimple, LockSimple } from '@phosphor-icons/react'
import background from './assets/background.jpg'
import Logo from 'src/assets/logo.svg'
import { useForm } from 'react-hook-form'
import { Input } from 'src/components/Input'
import { schema } from './controller'
import { Button } from 'src/components/Button'
import Link from 'next/link'
import { toast } from 'react-toastify'
import { signIn } from 'next-auth/react'
import { useState } from 'react'
import { useRouter } from 'next/navigation'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const router = useRouter()

  const onSubmit = async (data) => {
    setLoading(true)
    const response = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    })

    const { error, ok } = response

    if (error && !ok) {
      setLoading(false)
      toast.error(JSON.parse(error).message, {
        toastId: 'login-error',
      })
    }

    if (ok) {
      toast.success('Login realizado com sucesso!', {
        toastId: 'login-success',
      })
      setLoading(false)
      router.push('/dashboard/campanhas')
    }
  }

  return (
    <section className="md:grid md:grid-cols-[40%_60%] h-screen">
      <aside
        className="min-h-screen p-10 w-full bg-cover bg-no-repeat bg-center hidden md:block"
        style={{
          backgroundImage: `url(${background.src})`,
        }}
      >
        <Logo className="w-[140px]" />
      </aside>
      <aside className="flex-grow p-10 bg-[#fff] h-screen flex-col flex-center">
        <h2 className="text-[#344054] font-bold text-[30px] mb-[12px]">
          Boas-vindas!
        </h2>
        <p className="text-[#667085] font-normal">
          Faça login na sua conta para começar.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full mt-10 max-w-[360px] flex flex-col gap-6"
        >
          <Input
            placeholder="exemplo@email.com"
            label="Email"
            error={errors?.email?.message}
            icon={EnvelopeSimple}
            type="email"
            {...register('email')}
          />
          <Input
            error={errors?.password?.message}
            label="Senha"
            icon={LockSimple}
            placeholder="********"
            type="password"
            {...register('password')}
          />

          <div className="flex justify-end text-[#6A49D9] text-[14px] font-semibold">
            <Link href="recuperar-senha">Esqueceu a senha?</Link>
          </div>

          <Button
            type="submit"
            disabled={!isValid || loading}
            isLoading={loading}
          >
            Entrar
          </Button>

          <div className="flex-center text-[#6A49D9] text-[14px] font-semibold gap-1">
            <p className="text-[#70707B]">Não tem conta?</p>
            <Link href="cadastro">Cadastre-se.</Link>
          </div>
        </form>
      </aside>
    </section>
  )
}

export default Login
