import { WarningCircle } from '@phosphor-icons/react'
import { forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const Input = forwardRef(
  (
    {
      label,
      name,
      icon,
      error,
      onFocus,
      onBlur,
      secondIcon,
      onClickSecondIcon,
      classNameContainerInput,
      inputClassName,
      isTextArea = false,
      ...props
    },
    ref
  ) => {
    const Icon = icon
    const SecondIcon = secondIcon

    const [focus, setFocus] = useState(false)
    return (
      <div className="flex flex-col gap-2 w-full">
        {label && (
          <label
            className="text-[#70707B] font-medium text-[14px]"
            htmlFor={name}
          >
            {label}
          </label>
        )}

        <div
          className={twMerge(
            'flex-center gap-2 rounded-lg border border-[#D1D1D6] h-[44px] px-[14px] transition-all hover:border-[#5A35D4]',
            classNameContainerInput,
            focus && 'shadow-input border-[#5A35D4]',
            focus && error && 'shadow-input-error'
          )}
          style={{
            ...(error && { borderColor: '#F04438' }),
          }}
        >
          {icon && <Icon color="#D1D1D6" size={18} />}
          {isTextArea ? (
            <textarea
              autoComplete="off"
              className={twMerge(
                `p-0 !text-left text-[#3F3F46] focus:border-transparent border-none focus:!border-none focus:shadow-none focus:ring-0 flex-grow focus:outline-none h-full placeholder-[#D1D1D6]`,
                inputClassName
              )}
              id={name}
              name={name}
              {...props}
              ref={ref}
              onFocus={(event) => {
                onFocus && onFocus(event)
                setFocus(true)
              }}
              onBlur={(event) => {
                if (onBlur && event) {
                  onBlur(event)
                }
                setFocus(false)
              }}
              style={{
                ...(error && { color: '#F04438' }),
              }}
            />
          ) : (
            <input
              autoComplete="off"
              className={twMerge(
                `p-0 !text-left text-[#3F3F46] focus:border-transparent border-none focus:!border-none focus:shadow-none focus:ring-0 flex-grow focus:outline-none h-full placeholder-[#D1D1D6]`,
                inputClassName
              )}
              id={name}
              name={name}
              {...props}
              ref={ref}
              onFocus={(event) => {
                onFocus && onFocus(event)
                setFocus(true)
              }}
              onBlur={(event) => {
                if (onBlur && event) {
                  onBlur(event)
                }
                setFocus(false)
              }}
              style={{
                ...(error && { color: '#F04438' }),
              }}
            />
          )}

          {secondIcon && (
            <SecondIcon
              color="#D1D1D6"
              size={18}
              onClick={onClickSecondIcon}
              className={twMerge(onClickSecondIcon && 'cursor-pointer')}
            />
          )}
          {error && <WarningCircle color="#F04438" size={18} />}
        </div>
        {error && <span className="text-[14px] text-[#F04438]">{error}</span>}
      </div>
    )
  }
)

Input.displayName = 'Input'

export { Input }
